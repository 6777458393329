<template>
  <div class="workflow">
    <div class="workflow__wrapper">
      <div class="analytics" v-if="!$vuetify.breakpoint.smAndDown">
        <details-tab-workflow
          :runs="workflow.runs"
          :trigger="workflow.trigger"
          :schema="workflow.conditions"
          :inputs="workflow.inputs"
        />
      </div>

      <div class="flow-setup">
        <div class="d-flex pr-5" style="align-items: center">
          <v-breadcrumbs :items="breadcrumbs" style="font-weight: 600">
            <template v-slot:divider>
              <v-icon class="px-0">mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
          <v-spacer></v-spacer>

          <v-btn @click="$router.go(-1)" text rounded large>
            <v-icon left>mdi-chevron-left</v-icon> Back
          </v-btn>
        </div>

        <div class="flows">
          <div class="flow-setup__trigger">
            <div class="header" @click="showTriggers = !showTriggers">
              <span class="d-flex items-center">
                <v-btn color="#8F96A1" icon
                  ><v-icon size="33"
                    >mdi-chevron-right-circle-outline</v-icon
                  ></v-btn
                >
                <span class="title pl-1">{{ workflow.title }}</span></span
              >
              <v-btn color="primary" icon
                ><v-icon size="33" v-if="!showTriggers"
                  >mdi-chevron-down</v-icon
                >
                <v-icon size="33" v-else>mdi-chevron-up</v-icon>
              </v-btn>
            </div>
            <div>
              <transition name="animate-down">
                <trigger-workflow
                  v-if="showTriggers"
                  v-model="workflow.trigger"
                />
              </transition>
            </div>
          </div>

          <payment-trigger
            ref="paymentTrigger"
            v-model="workflow.payment"
            :isVisable="isPaymentTrigger"
            v-if="isPaymentTrigger"
          />

          <form-trigger
            ref="formTrigger"
            v-model="workflow.form"
            :isVisable="isFormTrigger"
            v-if="isFormTrigger"
          />

          <compose-workflow
            ref="conditions"
            v-model="workflow.conditions"
            :isVisable="canShowConditions"
            :trigger="workflow.trigger"
            @continue="workflow.composerIsReady = true"
            @inputs="workflow.inputs = $event"
            :trigger-data="
              isFormTrigger
                ? workflow.form
                : isPaymentTrigger
                ? workflow.payment
                : null
            "
            v-if="canShowConditions"
            @selected-fields="workflow.fields = $event"
          />
          <execute-actions-workflow
            v-if="canShowActions"
            ref="actions"
            :trigger="workflow.trigger"
            :organizationId="orgId.toString()"
            @publish="$refs.createWorkflow.start()"
            :isVisable="canShowActions"
            v-model="workflow.actions"
            :exception="workflow.exception"
            @exception="workflow.exception = $event"
            :exceptions="workflow.exceptionSchema"
            @exception-schema="workflow.exceptionSchema = $event"
            :inputs="workflow.inputs"
          />
        </div>
      </div>
    </div>

    <create-workflow
      ref="createWorkflow"
      :workflow="workflowPayload"
      @done="$router.replace('/workflow')"
    />
  </div>
</template>

<script>
import ExecuteActionsWorkflow from "../../components/workflow/actions/execute-actions-workflow.vue";
import ComposeWorkflow from "../../components/workflow/compose/compose-workflow.vue";
import detailsTabWorkflow from "../../components/workflow/details-tab-workflow.vue";
import TriggerWorkflow from "../../components/workflow/trigger-workflow.vue";
import FormTrigger from "../../components/workflow/trigger/form-trigger.vue";
import PaymentTrigger from "../../components/workflow/trigger/payment-trigger.vue";
import CreateWorkflow from "../../components/workflow/create-workflow.vue";

export default {
  components: {
    detailsTabWorkflow,
    TriggerWorkflow,
    PaymentTrigger,
    FormTrigger,
    ComposeWorkflow,
    ExecuteActionsWorkflow,
    CreateWorkflow,
  },
  data() {
    {
      return {
        publishDialog: false,
        publishDialogSucessful: false,
        showTriggers: false,
        scrollOptions: {
          duration: 500,
          offset: 0,
          easing: "easeInOutCubic",
          container: ".flows",
        },
        breadcrumbs: [
          {
            text: "Workflow",
            to: "/workflow",
            disabled: true,
          },
          {
            text: "rule",
            to: "#",
            disabled: true,
          },
          {
            text: "approval",
            disabled: false,
          },
        ],
        showAdvance: false,
        hypn_id: "",
        workflow: {
          title: this.$route.query.name || "untitled",
          trigger: "",
          runs: "0",
          conditions: null,
          fields: null,
          payment: null,
          form: null,
          composerIsReady: false,
          inputs: null,
          exception: true,
          exceptionSchema: null,
          actions: [
            // {
            //   type: "PbotApproval",
            //   properties: {
            //     keys: ["identity", "organization id", "type", "name"],
            //     values: [
            //       "musk@mail.com",
            //       "organization id value",
            //       "human",
            //       "approval",
            //     ],
            //   },
            // },
            // {
            //   type: "hyphenEmail",
            //   properties: {
            //     keys: [
            //       "subject",
            //       "message",
            //       "to",
            //       "cc",
            //       "organization id",
            //       "name",
            //     ],
            //     values: [
            //       "Next step",
            //       "I am buying amazon next",
            //       "musk@mail.com",
            //       ["johndoe@gmail.com", "musk@mail.com", "janedoe@gmail.com"],
            //       2,
            //       "email",
            //     ],
            //   },
            // },
            // {
            //   type: "hyphenAddToPayables",
            //   properties: {
            //     keys: ["total", "amount_due", "organization", "name"],
            //     values: ["5000", "8,000", 2, "payables"],
            //   },
            // },
            // {
            //   type: "hyphenUpdateCustomer",
            //   properties: {
            //     keys: ["attribute", "tag", "term", "organization", "name"],
            //     values: ["Tag", ["high value", "wema bank"], "", 2, "customer"],
            //   },
            // },
            // {
            //   type: "hyphenForm",
            //   properties: {
            //     keys: [
            //       "form id",
            //       "form name",
            //       "identity",
            //       "organization",
            //       "name",
            //     ],
            //     values: [3, "Form 3", "musk@mail.com", 2, "form"],
            //   },
            // },
            // {
            //   type: "hyphenToWorkflow",
            //   properties: {
            //     keys: ["workflow", "organization"],
            //     values: ["3", 2],
            //   },
            // },
            // {
            //   type: "hyphenDelay",
            //   properties: {
            //     keys: ["days", "organization", "type", "name"],
            //     values: [3, 2, "delay", "delay"],
            //   },
            // },
          ],
          webhook: "",
        },
        isPublishingWorkflow: false,
      };
    }
  },
  mounted() {
    this.breadcrumbs[2].text = this.$route.query.name || "untitled";
    this.showTriggers = true;
  },

  methods: {
    addWorkflowToDraft() {
      this.publishDialog = false;
    },
  },

  watch: {
    "workflow.trigger": {
      deep: true,
      handler() {
        this.workflow.form = null;
        this.workflow.payment = null;
        this.workflow.conditions = null;
      },
    },

    workflowPayload: {
      deep: true,
      immediate: true,
      handler(val) {
        if (process.env.NODE_ENV === "development") {
          console.log(JSON.stringify(val, null, 2));
        }
      },
    },

    canShowConditions(val) {
      if (val) {
        this.$nextTick(() => {
          this.$vuetify.goTo(this.$refs.conditions, this.scrollOptions);
        });
      }
    },

    canShowActions(val) {
      if (val) {
        this.$nextTick(() => {
          this.$vuetify.goTo(this.$refs.actions, this.scrollOptions);
        });
      }
    },

    isPaymentTrigger(val) {
      if (val) {
        this.$nextTick(() => {
          this.$vuetify.goTo(this.$refs.paymentTrigger, this.scrollOptions);
        });
      }
    },

    isFormTrigger(val) {
      if (val) {
        this.$nextTick(() => {
          this.$vuetify.goTo(this.$refs.formTrigger, this.scrollOptions);
        });
      }
    },
  },

  computed: {
    canShowConditions() {
      return (
        this.isInvoiceTrigger ||
        this.workflow.payment !== null ||
        this.workflow.form !== null
      );
    },

    canShowActions() {
      return this.workflow.composerIsReady;
    },

    isPaymentTrigger() {
      if (this.workflow.trigger && this.workflow.trigger == "payment") {
        return true;
      }
      return false;
    },

    isFormTrigger() {
      if (this.workflow.trigger && this.workflow.trigger == "form") {
        return true;
      }
      return false;
    },

    isInvoiceTrigger() {
      if (this.workflow.trigger && this.workflow.trigger == "invoice") {
        return true;
      }
      return false;
    },

    workflowPayload() {
      return {
        user: this.$store.getters["auth/user"]?.id,
        source: this.workflow.trigger,
        organization: this.orgId,
        workflow_schema: {
          condition: this.workflow.conditions,
          actions: this.workflow.actions,
        },
        trigger_schema: this.workflow.fields,
        form: this.workflow.form,
        payment: this.workflow.payment,
        is_active: 1,
        workflow_title: this.workflow.title,
        exception: this.workflow.exception,
        exception_schema: this.workflow.exceptionSchema,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.items-center {
  align-items: center;
}

.workflow {
  height: 100%;

  &__wrapper {
    // display: flex;
    display: relative;
    height: 100%;
    padding: 10px 0px;

    .analytics {
      width: 31%;
      height: 100%;
      position: absolute;
      padding-top: 30px;
      left: 0;
      top: 0;
      bottom: 0;

      &--embeded {
        display: none;
      }
    }

    .flows {
      flex: 1;
      overflow: auto;
      padding: 20px;
    }

    .flow-setup {
      position: absolute;
      // flex: 1;
      height: 100%;
      width: 69%;

      // mobile view
      @media (max-width: 768px) {
        width: 100%;
      }

      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      overflow: hidden;
      flex-direction: column;

      &--embeded {
        width: 100%;
      }

      &__trigger {
        width: 100%;
        padding: 30px;

        @media (max-width: 768px) {
          padding: 10px;
        }

        background: #fff;
        box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
        border-radius: 6px;

        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;

          .title {
            font-size: 30px;

            @media (max-width: 768px) {
              font-size: 20px;
            }

            font-weight: 700;
            color: #8f96a1;
            text-transform: capitalize;
          }
        }
      }
    }
  }
}

.animate-down {
  &enter {
    transform: translateY(-10px);
    opacity: 0;
  }
  &enter-active,
  &leave-active {
    transition: all 0.3s ease-out;
  }

  &leave-to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

// .publish {
//   border-radius: 8px;
//   background-color: #fff;
//   &__header {
//     padding: 20px;
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;

//     .t {
//       color: var(--v-primary-base);
//       font-weight: 600;
//       font-size: 20px;
//     }
//   }

//   &__content {
//     background-color: #fefcf8;
//     padding: 30px 50px;
//     text-align: center;

//     .msg {
//       font-size: 16px;
//       color: #757575;
//       line-height: 24px;
//       display: block;
//       margin-bottom: 30px;
//     }

//     #add-to-draft {
//       display: block;
//       margin: 50px auto 0px auto;
//       background: transparent;
//       color: #d7a47b;
//       cursor: pointer;
//       font-size: 17px;
//       border-bottom: 1px solid #d7a47b;
//     }
//   }
// }

// .publish-sucessful {
//   border-radius: 8px;
//   background-color: #fff;
//   &__header {
//     padding: 20px;
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;

//     .t {
//       color: var(--v-primary-base);
//       font-weight: 600;
//       font-size: 20px;
//     }
//   }

//   &__top {
//     box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
//     margin-bottom: 2px;
//     padding: 20px;
//     background-color: #f6f3ee;
//     span {
//       display: inline-block;
//       margin-left: 10px;
//       font-size: 21px;
//       font-weight: bold;
//       color: var(--primary-base);
//     }
//   }

//   &__content {
//     background-color: #f7f7f7;
//     padding: 20px 50px;

//     .msg {
//       font-size: 16px;
//       color: #757575;
//       line-height: 24px;
//       display: block;
//       margin-bottom: 30px;
//     }

//     .cta {
//       display: flex;
//       justify-content: end;
//     }
//   }

//   .api-cover {
//     display: flex;
//     align-items: center;
//     border: 1px solid #19283d1a;
//     box-sizing: border-box;
//     background: #ffffff;
//     border: 1px solid rgba(25, 40, 61, 0.1);
//     border-radius: 3px;
//     margin: 10px 0px;

//     &__req {
//       height: 55px;
//       padding: 0px 20px;
//       background-color: #f4f5f6;
//       color: #00233880;
//       font-weight: bold;
//       border-radius: 3px;
//       font-family: "Inter";
//     }

//     &__url {
//       padding: 0px 25px;
//       flex: 1;
//       color: #596a73;
//       white-space: nowrap;
//       text-overflow: ellipsis;
//       overflow: hidden;
//     }
//   }
// }
</style>
